import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Manager.vue'
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/home',  // 重定向到主页
    children: [
      { path: '403', name: 'Auth',meta:{name:'无权限'}, component: () => import('../views/manager/Auth') },
      { path: 'home', name: 'Home',meta:{name:'首页'}, component: () => import('../views/manager/Home') },
      { path: 'news', name: 'News',meta:{name:'新闻信息'}, component: () => import('../views/manager/News') },
      { path: 'user', name: 'User',meta:{name:'用户信息'}, component: () => import('../views/manager/User') },
      { path: 'users', name: 'Users',meta:{name:'普通用户信息'}, component: () => import('../views/manager/Users') },
      { path: 'person', name: 'Person',meta:{name:'个人信息'}, component: () => import('../views/manager/Person') },
      { path: 'password', name: 'Password',meta:{name:'修改密码'}, component: () => import('../views/manager/Password') },
      { path: 'newDetail', name: 'NewDetail',meta:{name:'详情页'}, component: () => import('../views/manager/NewDetail') },
      { path: 'notice', name: 'Notice',meta:{name:'系统公告'}, component: () => import('../views/manager/Notice') },
      { path: 'logs', name: 'Logs',meta:{name:'系统日志'}, component: () => import('../views/manager/Logs') },
      { path: 'charts', name: 'Charts',meta:{name:'数据统计'}, component: () => import('../views/manager/Charts') },
      { path: 'orders', name: 'Orders',meta:{name:'订单信息'}, component: () => import('../views/manager/Orders') },
      { path: 'diqiu', name: 'Diqiu',meta:{name:'地球'}, component: () => import('../views/manager/Diqiu') },

      { path: 'administrators', name: 'Administrators.',meta:{name:'管理员信息'}, component: () => import('../views/manager/Administrators') }

    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta:{name:'登录页面'},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta:{name:'注册页面'},
    component: () => import('../views/Register.vue')
  },
  {
    path: '*',
    name: '404',
    meta:{name:'无法访问'},
    component: () => import('../views/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // to 是到达的路由信息
  // from 是开源的路由信息
  // next 是帮助我们跳转路由的函数
  let adminPaths = ['/user']
  let user = JSON.parse(localStorage.getItem('hui-user') || '{}')

  if (user.role !== '超级管理员' && adminPaths.includes(to.path)) {
    // 如果当前登录的用户不是管理员，然后当前的到达的路径是管理员才有权限访问的路径，那这个时候我就让用户去到一个没有权限的页面，不让他访问实际的页面
    next('/403')
  } else {
    next()
  }
})

export default router
