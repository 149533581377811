<template>
  <div>
    <el-container>
      <!--    侧边栏  -->
      <el-aside :width="asideWidth" style="min-height: 100vh; background-color: #001529">
        <div style="height: 60px; color: white; display: flex; align-items: center; justify-content: center">
          <img src="@/assets/logo.png" alt="" style="width: 40px; height: 40px">
          <span class="logo-title" v-show="!isCollapse">hui2024</span>
        </div>

        <el-menu :collapse="isCollapse" :collapse-transition="false" router background-color="#001529" text-color="rgba(255, 255, 255, 0.65)" active-text-color="#fff" style="border: none" :default-active="$route.path">
          <el-menu-item index="/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">系统首页</span>
          </el-menu-item>
          <el-menu-item index="/diqiu">
            <i class="el-icon-headset"></i>
            <span slot="title">地球</span>
          </el-menu-item>
<!--          <el-submenu index="info">-->
<!--            <template slot="title">-->
<!--              <i class="el-icon-menu"></i>-->
<!--              <span>普通用户</span>-->
<!--            </template>-->
<!--          </el-submenu>-->
          <el-submenu index="info1" v-if="user.role==='管理员'">
            <template slot="title" >
              <i class="el-icon-menu"></i>
              <span>信息管理</span>
            </template>
            <el-menu-item index="/news">
              <i class="el-icon-headset"></i>
              <span slot="title">新闻信息</span>
            </el-menu-item>
            <el-menu-item index="/notice">
              <i class="el-icon-headset"></i>
              <span slot="title">系统公告</span>
            </el-menu-item>
            <el-menu-item index="/charts">
              <i class="el-icon-headset"></i>
              <span slot="title">信息统计</span>
              </el-menu-item>
            <el-menu-item index="/orders">
              <i class="el-icon-headset"></i>
              <span slot="title">订单信息</span>
            </el-menu-item>
            <el-menu-item index="/users">
              <i class="el-icon-headset"></i>
              <span slot="title">普通用户信息</span>

              </el-menu-item>

          </el-submenu>
          <el-submenu index="info1" v-if="user.role==='超级管理员'">
            <template slot="title" >
              <i class="el-icon-menu"></i>
              <span>信息管理</span>
            </template>
            <el-menu-item index="/news">
              <i class="el-icon-headset"></i>
              <span slot="title">新闻信息</span>
            </el-menu-item>
            <el-menu-item index="/user">
              <i class="el-icon-headset"></i>
              <span slot="title">用户信息</span>
            </el-menu-item>
            <el-menu-item index="/notice">
              <i class="el-icon-headset"></i>
              <span slot="title">系统公告</span>
            </el-menu-item>
            <el-menu-item index="/logs" >
              <i class="el-icon-headset"></i>
              <span slot="title">系统日志</span>
            </el-menu-item>
            <el-menu-item index="/charts">
              <i class="el-icon-headset"></i>
              <span slot="title">信息统计</span>
            </el-menu-item>
            <el-menu-item index="/orders">
              <i class="el-icon-headset"></i>
              <span slot="title">订单信息</span>
            </el-menu-item>
            <el-menu-item index="/Administrators">
              <i class="el-icon-headset"></i>
              <span slot="title">管理员信息</span>

            </el-menu-item>
            <el-menu-item index="/users">
              <i class="el-icon-headset"></i>
              <span slot="title">普通用户信息</span>

            </el-menu-item>

          </el-submenu>
        </el-menu>

      </el-aside>

      <el-container>
        <!--        头部区域-->
        <el-header>

          <i :class="collapseIcon" style="font-size: 26px" @click="handleCollapse"></i>
          <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-left: 20px">
            <el-breadcrumb-item :to="{ path: '/' }">主页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: $route.path }">{{ $route.meta.name }}</el-breadcrumb-item>
          </el-breadcrumb>

          <div style="flex: 1; width: 0; display: flex; align-items: center; justify-content: flex-end">
            <div style="margin-right: 30px">
              <p style="font-size: 20px">{{ times }}</p>
            </div>
              <div style="margin-right: 20px"><i class="el-icon-quanping" style="font-size: 26px" @click="handleFull"></i>
              </div>

              <el-dropdown placement="bottom">
              <div style="display: flex; align-items: center; cursor: default ;margin-right: 30px">
                <img  :src="user.avatar||'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" alt="头像" style="width: 40px; height: 40px; margin: 0 5px ;border-radius: 50%">
                <span>{{ user.name }}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="$router.push('/person')">个人信息</el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/password')">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

        </el-header>

        <!--        主体区域-->
        <el-main>
          <router-view @update:user="updateUser"/>

        </el-main>

      </el-container>


    </el-container>
  </div>
</template>

<script>

import axios from "axios";
import request from "@/utils/request";
export default {
  name: 'HomeView',
  data() {
    return {
      isCollapse: false,  // 不收缩
      asideWidth: '200px',
      collapseIcon: 'el-icon-s-fold',
      user:JSON.parse(localStorage.getItem('hui-user')||'{}'),
      times:'',//格式化之后的当前时间,
    }
  },
  mounted() {//页面加载完之后
    if (!this.user.id){//当浏览器没有用户信息
      this.$router.push('/login')
    }
    this.getTimes()


  },
  // 在Vue实例销毁前，清除我们的定时器
  beforeDestroy() {
    if (this.times) {
      clearInterval(this.getTimesInterval);
    }
  },
  methods: {
    updateUser(user){
      //获取组件传过来的数据 更新当前页面的数据
      this.user=JSON.parse(JSON.stringify(user))//让父级的对象跟子级的对象毫无关联
    },
    logout(){
      localStorage.removeItem("hui-user")//清除当前的token和用户数据
      this.$router.push('/login')
    },
    handleFull() {
      document.documentElement.requestFullscreen()
    },
    handleCollapse() {
      this.isCollapse = !this.isCollapse
      this.asideWidth = this.isCollapse ? '64px' : '200px'
      this.collapseIcon = this.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
    },
    getTimes(){
      setInterval(this.getTimesInterval, 1000);
    },
    getTimesInterval:function() {
      let _this = this;
      let year = new Date().getFullYear(); //获取当前时间的年份
      let month = new Date().getMonth() + 1; //获取当前时间的月份
      let day = new Date().getDate(); //获取当前时间的天数
      let hours = new Date().getHours(); //获取当前时间的小时
      let minutes = new Date().getMinutes(); //获取当前时间的分数
      let seconds = new Date().getSeconds(); //获取当前时间的秒数
      //当小于 10 的是时候，在前面加 0
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      //拼接格式化当前时间
      _this.times = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    },
  }
}
</script>

<style>
.el-menu--inline {
  background-color: #000c17 !important;
}
.el-menu--inline .el-menu-item {
  background-color: #000c17 !important;
  padding-left: 49px !important;
}
.el-menu-item:hover, .el-submenu__title:hover {
  color: #fff !important;
}
.el-submenu__title:hover i {
  color: #fff !important;
}
.el-menu-item:hover i {
  color: #fff !important;
}
.el-menu-item.is-active {
  background-color: #1890ff !important;
  border-radius: 5px !important;
  width: calc(100% - 8px);
  margin-left: 4px;
}
.el-menu-item.is-active i, .el-menu-item.is-active .el-tooltip{
  margin-left: -4px;
}
.el-menu-item {
  height: 40px !important;
  line-height: 40px !important;
}
.el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
}
.el-submenu .el-menu-item {
  min-width: 0 !important;
}
.el-menu--inline .el-menu-item.is-active {
  padding-left: 45px !important;
}
/*.el-submenu__icon-arrow {*/
/*  margin-top: -5px;*/
/*}*/

.el-aside {
  transition: width .3s;
  box-shadow: 2px 0 6px rgba(0,21,41,.35);
}
.logo-title {
  margin-left: 5px;
  font-size: 20px;
  transition: all .3s;   /* 0.3s */
}
.el-header {
  box-shadow: 2px 0 6px rgba(0,21,41,.35);
  display: flex;
  align-items: center;
}
</style>